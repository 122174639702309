import React from 'react';
import { required, useTranslate } from 'react-admin';
import { PasswordInputMod } from "Component/Layouts/Input/index";
import { EditMod, SimpleFormMod, ToolBarButtonModEdit, ActionsMod } from "Component/Layouts/Form/index";
import { RepeatPasswordValidation, PasswordValidation } from "Component/Validation/index";

export const AdminPasswordEdit = props => {
    const translate = useTranslate();
    return (<EditMod actions={<ActionsMod title={translate('resources.changePassword.name')} />} redirect={"/admins"} {...props} mutationMode="pessimistic" undoable={false}>
                <SimpleFormMod toolbar={<ToolBarButtonModEdit showCancel="true" hrefCancel="/cabinet/#/admins"/>} variant="outlined" encType="multipart/form-data">
                    <PasswordInputMod fullWidth label={translate('resources.changePassword.fields.password')} source="password" validate={[required(), PasswordValidation]}/>
                    <PasswordInputMod fullWidth label={translate('resources.changePassword.fields.passwordRepeat')}  source="passwordRepeat" validate={[required(), RepeatPasswordValidation]}/>
                </SimpleFormMod>
            </EditMod>);
};
