import { ReferenceManyField, Datagrid, Pagination } from 'react-admin'
import {DateFieldMod, EmailFieldMod, TextFieldMod} from "../../../../../Component/Layouts/Field";
import PetsField from "../../../../../Component/Layouts/Field/PetsField";
import ChatButton from "../../../../../Component/Layouts/Button/ChatButton";


const ClientsTab = () => {
    return (
        <ReferenceManyField
            reference="users"
            target="doctor"
            label={false}
            pagination={<Pagination />}
        >
            <Datagrid bulkActionButtons={false}>
                <TextFieldMod source="id" sortBy="id"/>
                <TextFieldMod source="name"/>
                <TextFieldMod source="phone"/>
                <EmailFieldMod source="email"/>
                <PetsField source="pets"/>
                <TextFieldMod label="resources.users.fields.subscription" source="subscription.expiredAt"/>
                <DateFieldMod source="lastVisitDate" showTime sortBy="lastVisitDate"/>
                <ChatButton/>
            </Datagrid>
        </ReferenceManyField>
    );
};

export default ClientsTab
