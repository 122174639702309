import { ReferenceManyField, Datagrid, Pagination } from 'react-admin'
import {DateFieldMod, TextFieldMod} from "../../../../../Component/Layouts/Field";

const ActivityTab = () => {
    return (
        <ReferenceManyField
            reference="admin-logs"
            target="admin"
            label={false}
            sort={{ field: 'createdAt', order: 'DESC' }}
            pagination={<Pagination />}
        >
            <Datagrid bulkActionButtons={false}>
                <TextFieldMod label="resources.admin_logs.fields.id" source="id" />
                <DateFieldMod label="resources.admin_logs.fields.createdAt" source="createdAt" showTime/>
                <TextFieldMod label="resources.admin_logs.fields.event" source="event"/>
            </Datagrid>
        </ReferenceManyField>
    );
};

export default ActivityTab
