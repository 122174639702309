import englishMessages from 'ra-language-english';

export const en = {
    ...englishMessages,
    menu: {
        settings: {
            name: 'Settings',
        },
        admins: {
            name: 'Admins',
        },
        users: {
            name: 'Users',
        },
        doctorsOnDuty: {
            name: 'Doctors on Duty'
        },
    },
    buttons:{
        changePassword: "Change password",
        chat: "Chat",
    },
    resources: {
        admins: {
            name: 'Admin |||| Admins',
            fields: {
                id: 'id',
                email: 'Email',
                role: 'Role',
                createdAt: 'Created At',
                isOnDuty: 'On Duty',
                createdBy: 'Created by',
                firstName: 'First Name',
                status: 'Status',
                lastName: 'Last Name',
                description: 'Description',
                photo: 'Photo',
                category: 'Category',
            },
            tabs: {
                information: 'Information',
                clients: 'Clients',
                appointments: 'Appointments',
                schedule: 'Schedule',
                income: 'Income',
                activity: 'Activity',
            }
        },
        changePassword: {
            name: 'Change password',
            fields: {
                password: 'Password',
                passwordRepeat: 'Password Repeat',
            },
        },
        doctors: {
            name: 'Doctor',
        },
        users: {
            name: 'User |||| Users',
            fields: {
                id: 'id',
                email: 'Email',
                type: 'Phone type',
                status: 'Status',
                phone: 'Phone',
                createdAt: 'Created At',
                updatedAt: 'Updated At',
                createdBy: 'Created by',
                name: 'Name',
                subscription: 'Subscription',
                isBlackList: 'Black list',
                pets: "Pets",
                doctor: 'Doctor',
                lastVisitDate: 'Last visit date',
                tabs: {
                    information: 'Information',
                }
            },
        },
        pets: {
            name: 'Pet |||| Pets',
            fields: {
                id: 'id',
                name: 'Name',
                petType: 'Pet type',
                photo: 'Photo',
                birtDate: 'Birth date',
                breed: 'Breed',
                gender: 'Gender',
                weight: 'Weight',
            },
        },
        appointments: {
            name: 'Appointment |||| Appointments',
            fields: {
                id: 'id',
                client: 'Client',
                status: 'Status',
                date: 'Date',
                dateStart: 'Start',
                dateEnd: 'End',
                createdAt: 'Created At',
                updatedAt: 'Updated At',
            },
        },
        admin_logs: {
            name: 'Activity |||| Activities',
            fields: {
                id: 'id',
                createdAt: 'Created At',
                event: 'Event',
            }
        }
    }
};
