import ukrainianMessages from 'ra-language-ukrainian';

export const ua = {
    ...ukrainianMessages,
    menu: {
        settings: {
            name: 'Налаштування',
        },
        doctorsOnDuty: {
            name: 'Лікарі на чергуванні',
        },
        admins: {
            name: 'Адміни',
        },
        users: {
            name: 'Користувачі',
        },
    },
    buttons:{
        changePassword: "Змінити пароль",
        chat: "Чат",
    },
    resources: {
        admins: {
            name: 'Адмін |||| Адміни',
            fields: {
                id: 'id',
                email: 'Email',
                role: 'Роль',
                createdAt: 'Коли створено',
                isOnDuty: 'На чергуванні',
                createdBy: 'Ким створено',
                firstName: 'Ім\'я',
                status: 'Статус',
                lastName: 'Прізвище',
                description: 'Опис',
                photo: 'Фото',
                category: 'Категорія',
            },
            tabs: {
                information: 'Інформація',
                clients: 'Клієнти',
                appointments: 'Призначення',
                schedule: 'Розклад',
                income: 'Дохід',
                activity: 'Активність',
            }
        },
        doctors: {
            name: 'Лікар',
        },
        changePassword: {
            name: 'Зміна паролю',
            fields: {
                password: 'Пароль',
                passwordRepeat: 'Пароль ще раз',
            },
        },
        users: {
            name: 'Користувач |||| Користувачі',
            fields: {
                id: 'id',
                email: 'Email',
                type: 'Тип телефона',
                status: 'Статус',
                phone: 'Телефон',
                createdAt: 'Коли створено',
                updatedAt: 'Коли змінено',
                createdBy: 'Ким створено',
                name: 'Ім\'я',
                subscription: 'Підписка',
                isBlackList: 'Чорний список',
                pets: "Тварини",
                doctor: 'Лікар',
                lastVisitDate: 'Дата останнього візити',
                tabs: {
                    information: 'Інформація',
                }
            },
        },
        pets: {
            name: 'Тварина |||| Тварини',
            fields: {
                id: 'id',
                name: 'Ім\'я',
                petType: 'Тип тварини',
                photo: 'Фото',
                age: 'Вік',
                birtDate: 'Дата народження',
                breed: 'Порода',
                gender: 'Стать',
                weight: 'Вага',
            },
        },
        appointments: {
            name: 'Призначення |||| Призначення',
            fields: {
                id: 'id',
                client: 'Клієнт',
                status: 'Статус',
                date: 'Дата',
                dateStart: 'Початок',
                dateEnd: 'Кінець',
                createdAt: 'Коли створено',
                updatedAt: 'Коли змінено',
            },
        },
        admin_logs: {
            name: 'Активність |||| Активність',
            fields: {
                id: 'id',
                createdAt: 'Коли створено',
                event: 'Подія',
            }
        }
    }
};
