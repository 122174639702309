import React from 'react';
import {TopToolbar, sanitizeListRestProps, FunctionField } from 'react-admin';
import {ListMod, DataGridMod} from "Component/Redesign/index";
import {CrudMod} from "Component/Layouts/Button/index";
import {Status} from "../Constant/Status";
import {StatusField, TextFieldMod, EmailFieldMod, DateFieldMod} from "Component/Layouts/Field/index";
import IsBlackListField from "../../../Component/Layouts/Field/IsBlackListField";
import {Boolean} from "../Constant/Boolean";
import PhoneTypeField from "../../../Component/Layouts/Field/PhoneTypeField";
import {PhoneType} from "../Constant/PhoneType";
import {ListFilter} from "../Filter/ListFilter";
import PetsField from "../../../Component/Layouts/Field/PetsField";


const ListActions = ({basePath, className, ...rest}) => {
    return (<TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        {/*      <CreateButtonMod basePath={basePath} label={'Create'} />*/}
    </TopToolbar>);
};

export const UsersList = ({permissions, ...props}) => {

    return (
        <ListMod {...props}
            actions={<ListActions/>}
            title="User management"
            basePath="/users"
            filters={<ListFilter/>}
        >
            <DataGridMod>
                <TextFieldMod source="id"/>
                <TextFieldMod source="name" sortable={false}/>
                <EmailFieldMod source="email" sortable={false}/>
                <TextFieldMod source="phone" sortable={false}/>
                <PhoneTypeField source='type' list={PhoneType} sortable={false}/>
                <StatusField source="status" statusList={Status}/>
                <IsBlackListField source="isBlackList" list={Boolean}/>
                <FunctionField source="doctor" render={record => record.doctor ? `${record.doctor.firstName} ${record.doctor.lastName}`: ''} sortable={false}/>
                <PetsField source="pets"/>
                <DateFieldMod label="resources.users.fields.subscription" source="subscription.expiredAt" showTime/>
                <DateFieldMod source="createdAt" showTime/>
                <DateFieldMod source="updatedAt" showTime/>
                <CrudMod show="true" edit="true" del="true"></CrudMod>
            </DataGridMod>
        </ListMod>
    );
};
