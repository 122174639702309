export const ACCESS_TOKEN_KEY = "token";
export const REFRESH_TOKEN_KEY = "refreshToken";

export const apiRoutes = {
    login: "/login",
    logout: "/logout",
    schedule: "/schedule",
};

export const routes = {
    login: "/login",
};