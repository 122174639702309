import * as React from "react";
import { Button, useTranslate } from 'react-admin';
import {Tooltip} from "@material-ui/core";

const ChatButton = (props) => {
    const translate = useTranslate();
    return (<div style={{textAlign: "center"}}>
        {(<Tooltip title={translate('buttons.chat')}>
            <Button variant="contained" color="primary" disableElevation>
                {translate('buttons.chat')}
            </Button>
        </Tooltip>)}
    </div>);
};

export default ChatButton;
