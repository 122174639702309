import React from 'react';
import { required, BooleanInput } from 'react-admin';
import {TextInputMod, SelectInputMod} from "Component/Layouts/Input/index";
import {EditMod, SimpleFormMod, ToolBarButtonModEdit, ActionsMod} from "Component/Layouts/Form/index";
import {EmailValidation} from "Component/Validation/index";
import {Status} from "../Constant/Status";
import {Boolean} from "../Constant/Boolean";

export const UsersEdit = props => {

    return (<EditMod actions={<ActionsMod title={'Edit User'} />} {...props} mutationMode="pessimistic" undoable={false}>
                <SimpleFormMod toolbar={<ToolBarButtonModEdit showCancel="true" hrefCancel="/cabinet/#/users"/>} variant="outlined" encType="multipart/form-data">
                    <TextInputMod fullWidth source="name" validate={required()}/>
                    <TextInputMod fullWidth source="email" validate={[required(), EmailValidation]}/>
                    <TextInputMod fullWidth source="phone" validate={[required()]}/>
                    <SelectInputMod fullWidth source="status" validate={required()} choices={Status}/>
                    <BooleanInput source="isBlackList" />
                </SimpleFormMod>
            </EditMod>
    );
};
