import React from 'react';
import {required, ImageField, Button, useDelete, useNotify, useRedirect, useRecordContext} from 'react-admin';
import {Status} from "Pages/Admin/Constant/Status";
import {TextInputMod, SelectInputMod, ImageInputMod} from "Component/Layouts/Input/index";
import {EditMod, SimpleFormMod, ToolBarButtonModEdit, ActionsMod} from "Component/Layouts/Form/index";
import {EmailValidation} from "Component/Validation/index";
import {Role} from "../Constant/Role";
import {Category} from "../Constant/Category";
import { useTranslate } from 'react-admin';


export const AdminsEdit = props => {

    const { record } = props;
console.log(props)
    return (
        <EditMod actions={<ActionsMod title={<PostTitle />}/>} {...props} mutationMode="pessimistic" undoable={false}>
            <SimpleFormMod toolbar={<ToolBarButtonModEdit showCancel="true" hrefCancel="/#/admins"/>}
                           variant="outlined" encType="multipart/form-data">
                <TextInputMod fullWidth source="firstName" validate={required()}/>
                <TextInputMod fullWidth source="lastName" validate={required()}/>
                <TextInputMod fullWidth source="email" validate={[required(), EmailValidation]}/>
                <TextInputMod fullWidth source="description" validate={[required()]}/>
                <SelectInputMod fullWidth source="status" validate={required()} choices={Status}/>
                <CategoryField />
                <ImageField fullWidth source="photo"/>
                    <DeleteImage/>
                <ImageInputMod fullWidth source="photo"/>
            </SimpleFormMod>
        </EditMod>
    );
};

const PostTitle = () => {
    const translate = useTranslate();
    const record = useRecordContext();
    return <span>{translate('ra.action.edit')} {record ? `${Role.find(el => el.id === record.role).name}` : ''}</span>;
};

const CategoryField = () => {
    const record = useRecordContext();

    return record.role === 'ROLE_DOCTOR' ?  (
        <SelectInputMod
            fullWidth
            label="resources.admins.fields.category"
            source="category"
            id="category"
            choices={Category}
        />
    ) :  ''
};

const DeleteImage = () => {
    const {id, photo} = useRecordContext();
    const notify = useNotify();
    const redirect = useRedirect();
    const [deleteImage] = useDelete();

    const handleDeleteImage = () => {
        const adminId = id;
        if (!adminId) {
            notify('Admin ID not found' + adminId, { type: 'error' });
            return;
        }

        deleteImage(
            'admins', // Resource name
            { id: `${adminId}/photo` }, // ID with the API endpoint
            {
                onSuccess: () => {
                    notify('Image deleted successfully');
                    window.location.reload();

                },
                onFailure: () => {
                    notify('Error: Could not delete image', { type: 'error' });
                }
            }
        );
    };

    return photo ? <Button onClick={handleDeleteImage} label="Delete Image" /> : null
}
