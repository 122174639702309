import React, { useState } from 'react';
import { required, minLength} from 'react-admin';
import {
    TextInputMod,
    PasswordInputMod,
    SelectInputMod,
    ImageInputMod
} from "Component/Layouts/Input/index";
import {CreateMod, SimpleFormMod, ToolBarButtonModCreate, ActionsMod} from "Component/Layouts/Form/index";
import {EmailValidation, RepeatPasswordValidation} from "Component/Validation/index";
import {Role} from "Pages/Admin/Constant/Role";
import {Category} from "../Constant/Category";
import { useTranslate } from 'react-admin';

export const AdminsCreate = props => {
        const translate = useTranslate();
        const [selectedRole, setSelectedRole] = useState(null);

        const handleRoleChange = (event) => {
            setSelectedRole(event.target.value);
        };

        return (<CreateMod actions={<ActionsMod title={translate('ra.action.create') + " Admin"}  />} {...props}>
            <SimpleFormMod toolbar={<ToolBarButtonModCreate showCancel="true" hrefCancel="/cabinet/#/admins"/>}
                           encType="multipart/form-data">
                <TextInputMod fullWidth label="First Name" source="firstName" validate={required()}/>
                <TextInputMod fullWidth label="Last Name" source="lastName" validate={required()}/>
                <SelectInputMod fullWidth label="Role" source="role" id="adminRole" validate={required()}
                                choices={Role} onChange={handleRoleChange}/>
                {selectedRole === 'ROLE_DOCTOR' && (
                    <SelectInputMod
                        fullWidth
                        label="Category"
                        source="category"
                        id="category"
                        choices={Category}
                        validate={required()}
                    />
                )}
                {selectedRole === 'ROLE_DOCTOR' && (
                    <TextInputMod
                        fullWidth
                        label="Phone"
                        source="phone"
                        id="phone"
                        validate={required()}
                    />
                )}
                <TextInputMod fullWidth label="Email" source="email" validate={[required(), EmailValidation]}/>
                <TextInputMod fullWidth label="Description" source="description" validate={[]}/>
                <PasswordInputMod fullWidth label="ra.auth.password" source="password" validate={[required(), minLength(8)]}/>
                <PasswordInputMod fullWidth label="Password Repeat" source="passwordRepeat"
                                  validate={[required(), RepeatPasswordValidation, minLength(8)]}/>
                <ImageInputMod label="Photo" source="photo"/>
            </SimpleFormMod>
        </CreateMod>)
};
